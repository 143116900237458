import SignCordsBlack from 'assets/icons/signCordsBlack.png';
import SignCordsWhite from 'assets/icons/signCordsWhite.png';
import SkeletonLoader from 'components/Molecules/SkeletonLoader';
import React, { useEffect, useState } from 'react';
import { Card, Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppContext } from '../../containers/App/AppContext';
import { IEtas, StoreService } from '../../services/StoreService';
import { OrderType } from '../../shared/models/Cart';
import Store from '../../shared/models/Store';
import Venue from '../../shared/models/Venue';
import { formatEta } from '../../shared/utils';
import {
  ClosedSignWrapper,
  MinimumTag,
  Sign,
  SignText,
  StandCardContainer,
  StyledSignCords,
  Wrapper,
} from './styled';

export enum StoreTypes {
  REGULAR = 'REGULAR',
  SUITE_KITCHEN = 'SUITE_KITCHEN',
}

export type StoreCardProps = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  store?: Store;
  showLoadingState: boolean;
  orderAheadSchedule?: string;
  venue?: Venue;
};

const ClosedSign = ({ text = 'CLOSED', signCords }: { text?: string; signCords: string }) => {
  return (
    <ClosedSignWrapper>
      <Wrapper>
        <StyledSignCords src={signCords} />
        <Sign>
          <SignText>{text}</SignText>
        </Sign>
      </Wrapper>
    </ClosedSignWrapper>
  );
};

const StoreImageSkeleton = styled.div`
  margin-top: -2px;
`;

export const StandCard = ({
  onClick,
  store,
  showLoadingState,
  orderAheadSchedule,
  venue,
}: StoreCardProps) => {
  const orderAheadEventTime = new Date(orderAheadSchedule || '');
  const orderAheadDate = orderAheadEventTime.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
  const orderAheadTime = orderAheadEventTime.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  const [orderNowEtas, setOrderNowEtas] = useState<IEtas | undefined>(undefined);
  const { orderType } = useAppContext();

  const getfulfillmentText = (etaMs?: number) => {
    if (orderAheadSchedule) return `${orderAheadDate}, ${orderAheadTime}`;

    if (orderNowEtas?.valid && etaMs)
      return formatEta(orderNowEtas.confidenceIntervalMs!, etaMs).toLocaleLowerCase();

    return 'N/A';
  };

  useEffect(() => {
    (async () => {
      if (!store || orderAheadSchedule || orderNowEtas) return;

      try {
        const { data } = await StoreService.GetStoreEtas(store.id);
        setOrderNowEtas(data);
      } catch {}
    })();
  }, [orderAheadSchedule, setOrderNowEtas, store]);

  return (
    <StandCardContainer className="storeSelected">
      <Card onClick={onClick}>
        {showLoadingState ? (
          <StoreImageSkeleton>
            <SkeletonLoader height={150} />
          </StoreImageSkeleton>
        ) : (
          store && (
            <>
              {<Image src={store.imageUrl} wrapped ui={false} />}
              {!store.active && (
                <ClosedSign signCords={store.imageUrl ? SignCordsWhite : SignCordsBlack} />
              )}
            </>
          )
        )}
        {store && (
          <Card.Content className="store_description">
            {store.active && +store.orderMinimum > 0 && (
              <MinimumTag image={store.imageUrl}>${store.orderMinimum} Minimum</MinimumTag>
            )}
            <Card.Header>{store.name}</Card.Header>
            {showLoadingState ? (
              <div>
                <SkeletonLoader height={20} width={100} />
                <SkeletonLoader count={2} />
              </div>
            ) : (
              <Card.Meta>{store.description}</Card.Meta>
            )}
          </Card.Content>
        )}
        {showLoadingState ? (
          <Card.Content extra>
            <Grid columns={2}>
              <Grid.Column>
                <SkeletonLoader />
              </Grid.Column>
              <Grid.Column textAlign="right">
                <SkeletonLoader />
              </Grid.Column>
            </Grid>
          </Card.Content>
        ) : (
          venue &&
          store && (
            <Card.Content extra>
              <Grid>
                {orderType === OrderType.PICKUP &&
                 venue?.id !== '88d36f56-ae9a-4346-be00-9f19b2aa5a66' && 
                 (
                  <Grid.Column>
                    {venue.pickupAvailable && store.pickupAvailable && (
                      <>
                        <p>Pick Up: {getfulfillmentText(orderNowEtas?.pickupEtaMs)}</p>
                        <span>{store.location}</span>
                      </>
                    )}
                  </Grid.Column>
                )}
                {orderType === OrderType.DELIVERY && (
                  <Grid.Column>
                    {orderType === OrderType.DELIVERY &&
                      venue.deliveryAvailable &&
                      venue?.id !== '88d36f56-ae9a-4346-be00-9f19b2aa5a66' && 
                      store.deliveryAvailable && (
                        <>
                          <p>Delivery: {getfulfillmentText(orderNowEtas?.deliveryEtaMs)}</p>
                        </>
                      )}
                  </Grid.Column>
                )}
              </Grid>
            </Card.Content>
          )
        )}
      </Card>
    </StandCardContainer>
  );
};

export const NoStoresCard = () => {
  const { orderType } = useAppContext();
  return (
    <StandCardContainer className="storeSelected">
      <Card>
        <Card.Content className="no_stores" textAlign="center" extra>
          {/* {`No stores are available for ${
            orderType === OrderType.DELIVERY ? 'delivery' : 'pickup'
          }`} */}
            Please Select your order time above
        </Card.Content>
      </Card>
    </StandCardContainer>
  );
};
